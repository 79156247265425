import React, { useEffect, useState } from 'react'
import Footer from '../../../components/Footer'
import { Link, navigate } from 'gatsby';
// import Footer from "../../components/Footer";
import Navbar from "../../../components/Navbar";
// import * as styles from "../../../pages/dashboard/dashboard.module.css"
// import * as style from "../../agents/agent.module.css"
// import * as style from './agent.module.css';
// import leftArrow from "../../../../static/images/Left-arrow.png"
// import rightArrow from "../../../../static/images/Right_arrow.png"
// import facebook from "../../../../static/images/facebook.svg"
// import twitter from "../../../../static/images/twitter.svg"
// import gmail from "../../../../static/images/gmail.svg"
// import insta from "../../../../static/images/insta.svg"
import { useLocation } from "@reach/router";
import "../../blogs/blogstyle.css"
// import * as styles from "../../../pages/seller/sellerProp.module.css"
// import { Card } from 'react-bootstrap';
// import Dummy from "../../../../static/images/home4.png"
import { CityLatLng, exclusiveProperty, featuredProperty, singleCity, blogCityList, getCityProperties } from '../../../components/Api/ListingApi';
// import Carousel from 'react-multi-carousel';
// import { blogList } from "../../components/Api/ListingApi";
// import icon from "../../../assets/img/home/icon.svg"
// import Cards from '../../../components/card/card';
// import SkeletonVerticalCard from '../../../components/card/skeletonVerticalCard';
import BlogSeo from '../../../components/BlogSeo';
// import FindhomeCity from './FindhomeCity';



function CityDetail(props) {

    const [loader, setLoader] = useState(false)
    const [cityDetail, setCityDetail] = useState([])
    const [url, setUrl] = useState(props.id)
    const [blogs, setBlogs] = useState([])
    const location = useLocation()
    const [storage, setStorage] = useState(false);
    // const [cityLatLong, setCityLatLong] = useState([])
    const [features, setFeatures] = useState([])
    const [city, setCity] = useState([])
    const [image, setImage] = useState("")
    const [title, setTitle] = useState("")
    // const data = [features[0], features[1], features[2], features[3]]

    // const array = ["1", "2", "3", "4"]
    // const responsive = {
    //     desktop: {
    //         breakpoint: { max: 4000, min: 1024 },
    //         items: 4,
    //         slidesToSlide: 3 // optional, default to 1.
    //     },
    //     tablet: {
    //         breakpoint: { max: 1024, min: 464 },
    //         items: 2,
    //         slidesToSlide: 2 // optional, default to 1.
    //     },
    //     mobile: {
    //         breakpoint: { max: 464, min: 0 },
    //         items: 1,
    //         slidesToSlide: 1 // optional, default to 1.
    //     }
    // };

    useEffect(() => {
        handleNavigate(url)
    }, [url])

    useEffect(async () => {
        const storedData = localStorage.getItem("userInfo");
        if (!storedData) {
            setStorage(false);
        } else {
            setStorage(true);
        }
        await getSingleCity()
        cityList()
        // await getLatLng()
    }, []);
    // var blogs
    // console.log(window.location.pathname,props.id);
    const getSingleCity = async () => {
        await singleCity(props.id).then((res) => {
            // console.log("response", res.data.data.neighborhood)
            if (res.status == 200) {
                setCityDetail(res.data.data)
                setTitle(res.data.data.title.replace(", USA", ""))
                setBlogs(res.data.data.neighborhood.split(", "))
                let getParams = {
                    address: res.data.data.title
                }
                const parser = new DOMParser();
                const doc = parser.parseFromString(res.data.data.content, 'text/html');
                // console.log(doc);
                const imageElement = doc.querySelector('img');
                // console.log(imageElement.style);
                setImage(imageElement.src)
                // console.log();
                var datas = res.data.data.title.split(",")[0]
                setLoader(true)
                getCityProperties(datas).then((res) => {
                    // CityLatLng(getParams).then((res) => {
                    //     if (res.status == 200) {
                    // console.log(res.data.data)
                    //         setCityLatLong(res.data.data)
                    //         let params = {
                    //             longitude: res.data.data[0],
                    //             latitude: res.data.data[1],
                    //         }
                    //         setKey(true)
                    //         featuredProperty(params).then((res) => {
                    //             console.log(res.data.data)
                    //             setKey(false)

                    setFeatures(res.data.data)
                    setLoader(false)
                })

                // }

                // })
            }

        })
    }

    const cityList = () => {
        let getParam = {
            pagesize: 20,
            page: 1,
            sort_value: -1,
            sort_field: "updatedAt"
        }
        blogCityList(getParam).then((res) => {
            // console.log("response", res.data.data);
            setCity(res.data.data)
        }).catch((err) => {
            console.log(err);
        })
    }
    // var imgSrcs
    //for removing exclude image tag
    //     const parser = new DOMParser();
    //     // const doc = parser.parseFromString(props.content, 'text/html');
    //     // const imgElements = doc.getElementsByTagName('p');
    //     const imgElements = doc.querySelector('p');
    //     for (let i = imgElements.length - 1; i >= 0; i--) {
    //       const img = imgElements[i];
    //       imgSrcs = img.setAttribute('class',"highlight");
    //       console.log("imgSrcs",imgSrcs);
    //     //   img.parentNode.removeChild(img);
    //     }
    // // console.log("imgSrcs",imgSrcs);
    //     const htmlWithoutImg = new XMLSerializer().serializeToString(doc);


    // //for only img tag
    //     const getImageTagFromHTML=()=> {
    //         const parser = new DOMParser();
    //         const doc = parser.parseFromString(props.content, 'text/html');
    //         console.log(doc);
    //         const imgTag = doc.querySelector('p');
    //         console.log(imgTag);
    //         var content =document.getElementsByTagName("p").innerHTML
    //         // let str = document.getElementById("demo").innerHTML; 
    // let res = content.replace("<p", "<p className='paraContent'");
    // console.log("res",res);
    // document.getElementById("demo").innerHTML = res;
    //     return imgTag;
    //   }
    //     const imgTag = getImageTagFromHTML();
    //     const imgSrc = imgTag.setAttribute('class',"highlight");
    //     const imgAlt = imgTag.getAttribute('alt');
    // console.log(imgSrc);


    // const backendJSX = '<p>This is the JSX from the backend</p>'; // Received JSX from the backend
    // const dynamicClassName = 'highlight'; // Dynamic class name

    // Modify the received JSX by adding the dynamic class name
    // const modifiedJSX = React.createElement('p', { className: dynamicClassName }, cityDetail.content.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, " "));

    const handleNavigate = (item) => {
        navigate(`/city/${item}`)
        singleCity(item).then((res) => {
            // console.log("response", res.data.data.neighborhood)
            if (res.status == 200) {
                setCityDetail(res.data.data)
                setTitle(res.data.data.title.replace(", USA", ""))
                setBlogs(res.data.data.neighborhood.split(", "))
                let getParams = {
                    address: res.data.data.title
                }
                var datas = res.data.data.title.split(",")[0]
                setLoader(true)
                getCityProperties(datas).then((res) => {
                    // console.log(res.data.data);
                    // CityLatLng(getParams)
                    // if (res.status == 200) {
                    //         console.log(res.data.data)
                    //         setCityLatLong(res.data.data)
                    //         let params = {
                    //             longitude: res.data.data[0],
                    //             latitude: res.data.data[1],
                    //         }
                    // setKey(true)
                    //         featuredProperty(params).then((res) => {
                    //             console.log(res.data.data)
                    //             setKey(false)
                    setFeatures(res.data.data)
                    setLoader(false)

                })
                //     }

                // })
            }

        })
    }
    return (

        <div>
            {
                url && cityDetail &&

                <BlogSeo title={cityDetail.metaTitle} description={cityDetail.metaDescription} url={props.id} data={cityDetail} image={image} />
            }

            <Navbar isLogin={storage} />
            <div>
                <span className="atf-scroll-top atf-back-to-top" data-targets="html" id="backtotop">
                    <i className="fa fa-angle-up atf-scrollup-icon"></i>
                </span>

                <div className="Secondary-page blogdetail">
                    <div className="atf-special-offer terms-section">
                        <div className="container center-container">
                            <div className="row section-vertical-middle py-3">

                                <div className="col-xl-12 col-lg-12 col-12 text-left">

                                    <div className="atf-special-content atf-section-title mx-5 my-auto mb-5">
                                        <div className="col-xl-12 col-lg-12 col-12 text-center">
                                            <h1 className="blog-title ConttentTitile">{cityDetail.title}</h1>
                                            <h3 className="blog-title ConttentTitile">{cityDetail.auther}</h3>
                                        </div>
                                        <div className="row section-vertical-top ">
                                            <div className="col-xl-12 col-lg-12 col-12 text-left">
                                                {(cityDetail.thumbNileImage !== undefined && cityDetail.thumbNileImage !== "") ?
                                                    <div className='row section-vertical-top'>
                                                        {/* <div className='row section-vertical-top' style={{ height: "670px", overflow: "auto" }}> */}

                                                        <div className="col-xl-6 col-lg-6 col-6 blog-banner mb-5  " style={{ height: "590px" }}>
                                                            <img src={process.env.API_IMAGE_URL + cityDetail.thumbNileImage} alt="real estate market" title="real estate market" crossOrigin="anonymous" style={{ width: "100%", height: "100%" }} />
                                                        </div>
                                                        <div className="col-xl-6 col-lg-6 col-6 blogScroll ">
                                                            <h4 className='ConttentTitile' style={{ fontWeight: "500", fontSize: "28px", padding: "0px 20px 0 12px" }}>Overview</h4>
                                                            {/* <p className="cityContent" >{props.content.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, " ")}</p> */}
                                                            {/* <div> */}
                                                            <div className="highlight" dangerouslySetInnerHTML={{ __html: cityDetail.content }}></div>
                                                            {/* </div> */}


                                                        </div></div>
                                                    :
                                                    <div className='row section-vertical-top' style={{ width: "100%" }}>
                                                        <div className="highlight" style={{ marginBottom: "16px" }} dangerouslySetInnerHTML={{ __html: cityDetail.content }}></div>
                                                        {/* {modifiedJSX} */}
                                                    </div>
                                                }
                                                {/* {features.length !== 0 &&  */}
                                                {/* {loader ?
                                                    <div style={{ backgroundColor: "#f4f4f4", padding: "15px 0px 15px 0px", marginTop: "21px" }}>
                                                        <div>
                                                            <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "27px", padding: "15px 0px", fontFamily: "roboto , sans-serif" }}>Featured Properties</p>
                                                        </div>

                                                        <div className={styles.carouselRes}>
                                                            <Carousel
                                                                swipeable={false}
                                                                draggable={false}
                                                                responsive={responsive}
                                                                ssr={true} // means to render carousel on server-side.
                                                                autoPlaySpeed={1000}
                                                                keyBoardControl={true}
                                                                customTransition="all .5"
                                                                transitionDuration={500}
                                                                containerClass="carousel-container"
                                                                removeArrowOnDeviceType={["mobile"]}
                                                                dotListClass="custom-dot-list-style"
                                                                itemClass="carousel-item-padding-40-px"
                                                            >
                                                                {array.map((item) => (
                                                                    <div >
                                                                        <div className={styles.CarouselCard1}>
                                                                            <SkeletonVerticalCard purpose={"card"} />
                                                                        </div></div>
                                                                ))}
                                                            </Carousel>
                                                        </div>
                                                    </div   >
                                                    :
                                                    features.length !== 0 &&
                                                    <div style={{ backgroundColor: "#f4f4f4", padding: "15px 0px 15px 0px", marginTop: "21px" }}>
                                                        <div>
                                                            <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "27px", padding: "15px 0px", fontFamily: "roboto , sans-serif" }}>Featured Properties</p>
                                                        </div>

                                                        <div className={styles.carouselRes}>
                                                            <Carousel
                                                                swipeable={false}
                                                                draggable={false}
                                                                responsive={responsive}
                                                                ssr={true} // means to render carousel on server-side.
                                                                autoPlaySpeed={1000}
                                                                keyBoardControl={true}
                                                                customTransition="all .5"
                                                                transitionDuration={500}
                                                                containerClass="carousel-container"
                                                                removeArrowOnDeviceType={["mobile"]}
                                                                dotListClass="custom-dot-list-style"
                                                                itemClass="carousel-item-padding-40-px"
                                                            >

                                                                {features.map((data, index) => (
                                                                    // return (
                                                                    <div key={index}>
                                                                        <div className={styles.CarouselCard1}>
                                                                            <Cards data={data} I={index} style={true} />
                                                                        </div>
                                                                    </div>
                                                                )
                                                                )}
                                                            </Carousel>
                                                        </div>
                                                    </div   >
                                                } */}
                                                {/* {console.log(blogs)} */}
                                                {blogs[0] !== "" &&
                                                    <div>
                                                        <p className='neighbourCity' >Neighborhoods situated with in the {title} areas</p>
                                                        <div className='blogList'>
                                                            {blogs[0] !== "" && blogs.map((item) => (
                                                                <div >
                                                                    <p className='cityBlogs'>{item}</p>
                                                                </div>
                                                            ))}

                                                        </div>
                                                    </div>
                                                }
                                                <div>
                                                    {/* <div> */}
                                                    <h3 className='mt-3' style={{ fontSize: "33px" }}>Find your dream home in popular cities</h3>
                                                    <div className='row mt-3'>
                                                        {/* <div className='col-md-2'>{ */}

                                                        {city.length !== 0 && city.map((item) => (
                                                            <div className='col-md-3' >
                                                                <> <p onClick={() => { setUrl(item.url) }} className='cityNames' style={{ marginBottom: "0px" }}>{item.title} Real Estate</p><br /></>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                {/* </div> */}
                                                {/* <div>
                                                    <h3 className='mt-3 mb-3' style={{ fontSize: "33px" }}>Find your dream home in popular cities</h3>
                                                    <div className='row mt-3'> */}
                                                {/* <div className='col-md-2'>{ */}
                                                {/* <div className='col-md-3' >
                                                            {city.map((item) => (
                                                                <> <p onClick={() => { setUrl(item.url) }} className='cityNames' style={{marginBottom:"0px"}}>{item.title} Real Estate</p><br /></>
                                                            ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
            <Footer isLogin={storage} />
        </div>
    )
}

export default CityDetail