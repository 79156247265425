import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function BlogSeo({ description, lang, meta, title, link, url, data, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
           
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  React.useEffect(() => {
    // react-helmet does not prepend so need to do it manually
    const meta = document.createElement('meta');
    meta.setAttribute('property', 'og:site_name');
    meta.setAttribute('content', "Go KeyWe");
    const head = document.querySelector('head');
    head.insertBefore(meta, head.firstChild);
  }, []);
  //   React.useEffect(() => {
  //   const meta = document.createElement('meta');
  //       meta.setAttribute('property', 'og:url');
  //       meta.setAttribute('content',url);
  //       const head = document.querySelector('head');
  //       head.insertBefore(meta, head.firstChild);
  // }, []);
  //   React.useEffect(() => {
  //         const meta = document.createElement('meta');
  //         meta.setAttribute('property', 'og:description');
  //         meta.setAttribute('content', description);
  //         const head = document.querySelector('head');
  //         head.insertBefore(meta, head.firstChild);
  //   }, []);
  //     React.useEffect(() => {
  //         const meta = document.createElement('meta');
  //         meta.setAttribute('property', 'og:title');
  //         meta.setAttribute('content',title);
  //         const head = document.querySelector('head');
  //         head.insertBefore(meta, head.firstChild);
  //   }, []);
  //   React.useEffect(() => {
  //     const meta = document.createElement('meta');
  //     meta.setAttribute('property', 'og:author');
  //     meta.setAttribute('content',data.author);
  //     const head = document.querySelector('head');
  //     head.insertBefore(meta, head.firstChild);
  // }, []);
  // React.useEffect(() => {
  //   const meta = document.createElement('meta');
  //   meta.setAttribute('property', 'og:keywords');
  //   meta.setAttribute('content',data.keywords);
  //   const head = document.querySelector('head');
  //   head.insertBefore(meta, head.firstChild);
  // }, []);
  // React.useEffect(() => {
  //   const meta = document.createElement('meta');
  //   meta.setAttribute('property', 'og:title');
  //   meta.setAttribute('content',title);
  //   const head = document.querySelector('head');
  //   head.insertBefore(meta, head.firstChild);
  // }, []);
  // console.log(title);
  return (
    <div>
      <link rel="canonical" href="https://www.gokeywe.com" />
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={data.title}
        titleTemplate={data.title}
        description={data.description}
        meta={[
          {
            property: `og:locale`,
            content: `en_US`
          },
          {
            property: `og:url`,
            content: data.url
          },
          {
            property: `og:title`,
            content: data.title
          },
          {
            property: `og:meta_title`,
            content: data.metaTitle
          },
          {
            property: `og:meta_description`,
            content: data.metaDescription
          },
          {
            property: `og:keywords`,
            content: data.keywords
          },
          image !== "" && {
            property: `og:image`,
            content: image
          },
          data.author !== undefined && {
            property: `og:author`,
            content: data.author !== undefined && data.author
          },
          // 
          {
            property: `og:site_name`,
            content: `Go KeyWe`
          },
          {
            property: `og:image`,
            content: `https://gokeywe.com/images/keyweUpdatedlogo.png`
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`
          },
          {
            name: `twitter:url`,
            content: `https://gokeywe.com/`
          },
          {
            name: `twitter:image`,
            content: `https://gokeywe.com/pro/assets/img/home/home-section2.png`
          },
          {
            name: `twitter:title`,
            content: `GoKeyWe: Off-market listings and top-rated agents`
          },
          {
            name: `twitter:description`,
            content: `Access Off Market and Broker Exclusives through the premier Agent and Broker-Driven site for property listings, services and AI-driven tools. This is where REAL agents connect with REAL buyers and sellers.`
          },
          // {
          //   name: `google-site-verification`,
          //   content: `1K9U183nKTu2mpBtAmw0Pb58FoTtYx-XiY_tXOXzEtI`,
          // },
        ].concat(meta)}

      />
    </div>

  )
}

BlogSeo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

BlogSeo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default BlogSeo